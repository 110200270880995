.theme(bright) {
  @import (multiple) "../../../bower_components/bootstrap-less/less/variables.less";

  @banner-url: url("../../img/banner-bright.png");

  @body-bg: rgb(255, 255, 255);
  @brand-primary: #00640e;
  @brand-success: rgb(35, 161, 35);
  @brand-info: rgb(117, 202, 235);
  @brand-warning: rgb(255, 133, 27);
  @brand-danger: rgb(255, 65, 54);
  @brand-danger-message: @brand-danger;
  @text-color: rgb(0, 0, 0);
  @link-color: rgb(12, 164, 63);
  @headings-color: rgb(21, 154, 9);
  @btn-default-color: @text-color;
  @btn-default-bg: @gray-lighter;
  @btn-default-active-bg: lighten(@gray-lighter, 10%);
  @btn-default-border: darken(@btn-default-bg, 5%);
  @input-border: @navbar-default-border;
  @input-color-placeholder: @gray-light;
  @input-height-base: (@line-height-computed + (@padding-base-vertical * 2) + 4);
  @dropdown-border: @navbar-default-border;
  @dropdown-divider-bg: @gray-lighter;
  @dropdown-link-hover-bg: transparent;
  @navbar-default-color: @text-color;
  @navbar-default-bg: rgb(6, 161, 40);
  @navbar-default-link-color: rgb(255, 255, 255);
  @navbar-default-link-hover-color: @navbar-default-link-color;
  @navbar-default-link-hover-bg: lighten(@navbar-default-bg, 5%);
  @navbar-default-link-active-color: @navbar-default-link-color;
  @navbar-default-link-active-bg: darken(@navbar-default-bg, 5%);
  @navbar-default-link-disabled-color: @gray-lighter;
  @navbar-default-toggle-hover-bg: rgb(255, 255, 255);
  @navbar-default-toggle-icon-bar-bg: @gray-light;
  @navbar-default-toggle-border-color: @gray-lighter;
  @navbar-inverse-bg: rgb(255, 255, 255);
  @navbar-inverse-link-hover-color: @gray-dark;
  @navbar-inverse-link-active-bg: transparent;
  @navbar-inverse-link-disabled-color: @gray-lighter;
  @navbar-inverse-brand-hover-color: @navbar-inverse-link-hover-color;
  @navbar-inverse-toggle-hover-bg: @gray-lighter;
  @navbar-inverse-toggle-icon-bar-bg: @gray-light;
  @navbar-inverse-toggle-border-color: @gray-lighter;
  @nav-link-hover-bg: rgb(255, 255, 255);
  @nav-tabs-border-color: @navbar-default-border;
  @nav-tabs-active-link-hover-bg: rgb(255, 255, 255);
  @nav-tabs-active-link-hover-border-color: @nav-tabs-border-color;
  @nav-tabs-justified-link-border-color: @nav-tabs-border-color;
  @pagination-bg: @btn-default-bg;
  @pagination-border: @btn-default-border;
  @pagination-hover-bg: @pagination-bg;
  @pagination-hover-border: @pagination-border;
  @pagination-disabled-bg: @pagination-bg;
  @pagination-disabled-border: @pagination-border;
  @state-success-text: @brand-success;
  @state-success-bg: rgb(255, 255, 255);
  @state-info-text: @brand-info;
  @state-info-bg: rgb(255, 255, 255);
  @state-warning-text: @brand-warning;
  @state-warning-bg: rgb(255, 255, 255);
  @state-danger-text: @brand-danger;
  @state-danger-bg: rgb(255, 255, 255);
  @popover-arrow-color: rgb(255, 255, 255);
  @progress-bg: rgb(250, 250, 250);
  @panel-inner-border: transparent;
  @panel-default-border: transparent;
  @panel-primary-border: transparent;
  @panel-success-border: transparent;
  @panel-info-border: transparent;
  @panel-warning-border: transparent;
  @panel-danger-border: transparent;
  @thumbnail-border: @gray-lighter;
  @well-bg: rgb(250, 250, 250);
  @breadcrumb-bg: @well-bg;
  @breadcrumb-color: @gray-light;
  @table-bg: rgb(255, 255, 255);
  @table-border-color: #b0b0b0;
  @table-bg-accent: #f9f9f9;
  @table-bg-hover: #f5f5f5;
  @table-bg-active: @table-bg-hover;

  @import (multiple) "../bootstrap.less";
  @import (multiple) "../../../bower_components/bootstrap-switch/src/less/bootstrap3/bootstrap-switch.less";
  @import (multiple) "../nzbhydra.less";

  .nav-tabs > li > a:hover {
    border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color transparent;
    background-color: transparent;
  }

  @block-overlay-bg: rgb(0, 0, 0);
  @block-message-bg: @navbar-inverse-bg;
  @block-message-color: @brand-primary;
  @input-color-placeholder: #c1c1c1;
  @input-group-addon-color: @input-color;
  @switch-color: @btn-primary-color;

  .bootstrap-switch .bootstrap-switch-label {
    background-color: @body-bg;
  }

  @chart-text-color: @text-color;
  @chart-bar-color: lighten(@brand-primary, 10%);
  @chart-bar-color-alt: darken(@brand-primary, 3%);

  #loading-bar .bar {
    background-color: @text-color !important;
  }

  #loading-bar-spinner .spinner-icon {
    border-top-color: @text-color !important;
    border-left-color: @text-color !important;
  }
}
