a.expand-collapse:focus {
  text-decoration: none;
}

a.disabled {
  color: @gray-dark;
  text-decoration: none;
  cursor: default;
}

a.disabled:hover {
  color: @gray-dark;
  text-decoration: none;
  cursor: default;
}

a.glyph-link, a.glyph-link:hover {
  text-decoration: none;
}

.cursor-default {
  cursor: pointer;
}

.no-underline {
  text-decoration: none !important;
}


// Typography (like Bootstrap 5)
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }


.title-group-expanded {
  background-color: fade(darken(@table-bg, 5%), 35%);
}

pre {
  min-height: 50px;
}

.history-title {
  font-style: italic;
  color: @input-color-placeholder;
}

.tooltip-inner {
  max-width: 350px;
}

.caption {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 5px;
  color: @brand-primary;
  text-align: left;
}

.warning {
  color: @brand-warning;
}

.error, .danger {
  color: @brand-danger;
}

.error-message {
  color: @brand-danger-message;
}

.indexerStatusHasResults {
  color: @brand-success;
}
