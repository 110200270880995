.form-control {
  height: 36px;
}

.form-control::-webkit-input-placeholder {
  color: @input-color-placeholder;
}

.form-control:-moz-placeholder {
  color: @input-color-placeholder;
}

.form-control::-moz-placeholder {
  color: @input-color-placeholder;
}

.form-control:-ms-input-placeholder {
  color: @input-color-placeholder;
}

// Custom to prevent priority input from looking odd with form-inline in xs mobile view
.form-indexer {
  .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .input-group-addon {
    width: auto;
  }
}

.form-search {
  .control-label {
    padding-top: 7px;
  }
}


// Do not think these are used anywhere
.search-input {
  width: 80%;
}
.config-field {
  width: 400px;
}
.checkbox-field {
  width: auto;
}
.config-select {
  margin: 30px;
  text-align: left;
}
.config-field-container {
  margin: 30px;
  text-align: left;
}
.config-select-field > button {
  background-color: @gray-darker;
}
.other-duplicate-toggle {
  margin-left: 18px;
}
.toggle-placeholder {
  margin-left: 36px
}



.form-control-lg {
  min-height: 46px;
  border-radius: 6px;
  font-size: 18px;
  line-height: normal;
}

.episode-input {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-radius: 0;
}

.season-input {
  border-radius: 0;
}

.search-border {
  border-bottom-right-radius: @border-radius-base !important;
  border-top-right-radius: @border-radius-base !important;
  //border-right-width: 0px !important;
}

.search-category-button {
  min-width: 105px; //Enough for "Movies HD"
  text-align: left;
}

.by-id-checkbox {
  font-size: @font-size-base;

  color: @input-color;
  background-color: @input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid @input-border;
  border-right: 0px;

  border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.

  // Reset height for `textarea`s
  textarea& {
    height: auto;
  }
}


.ui-select-bootstrap .ui-select-choices-row > a {
  background-color: @input-bg;
  color: @input-color;
}

.ui-select-match > .btn {
  background-color: @input-bg;
  color: @input-color;
}


.input-group-btn1 {
  border: 1px solid @input-border;
  border-left-width: 0;
  border-radius: @border-radius-base;
}

.input-group-btn2 {
  border: 1px solid @input-border;
  border-radius: @border-radius-base;
}

.input-group-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left-width: 0;
}

.indexer-checkbox {
  margin-left: 10px !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.help-block {
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;

  a {
    text-decoration: underline;
  }

  code {
    background-color: unset !important;
  }
}

input:focus + .input-group-addon {
  @color-rgba: rgba(red(@input-border-focus), green(@input-border-focus), blue(@input-border-focus), .6);
  border-color: @input-border-focus;
  outline: 0;
  .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-rgba}");
}

.input-group-addon {
  cursor: default;
}

.input-group-addon {
  color: @input-group-addon-color;
}

.has-error {
  color: @brand-danger;
}

.has-error .control-label {
  color: @brand-danger;
}

.indexer-score-input {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-radius: 0;
}

.indexer-score-input-enabled {
  border-color: @brand-success;
}

.indexer-input-input-enabled:focus {
  border-color: @brand-success;
}

.indexer-score-input-disabled {
  border-color: @btn-default-border;
}

.indexer-score-input-disabled:focus {
  border-color: @btn-default-border;
}

.inline-filter { //Filter box
  display: inline;
  width: 300px;
  padding-left: 5px;
  font-size: @font-size-base;
  height: 24px;
  margin-left: 5px;
}

.indexer-input:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.indexer-input-focused {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

select.form-control {
  padding-left: 8px; //Content has some paddding, so reduce padding to make actual/visual padding the same as the others
}

.input-lg {
  font-size: 16px;
}

.formly-field-duoSetting > div {
  margin-bottom: 0;
}

.formly-multiselect {
  float: left;
}

.formly > .formly-field-group {
  position: relative;
  background-color: fade(@modal-content-bg, 50%);
  border: 1px solid @modal-content-border-color;
  border-radius: @border-radius-large;
  //.box-shadow(0 1px 5px rgba(0, 0, 0, .5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

:is(.formly > .formly-field-group):has(> fieldset.ng-hide) {
  display: none;
}

.formly-field-repeatSection {

  .repeatsection {
    box-shadow: none;
    border: none;
    margin-top: 25px;
  }

}

.formly-field-group {
  margin-bottom: 25px;
}

@media (min-width: @screen-sm-min) {
  .config-help {
    padding-left: 0;
    padding-right: 20px;
  }
}

.repeatWrapperClass {
  background-color: fade(@modal-content-bg, 25%);
  border: 1px solid @modal-content-border-color;
  margin-top: 25px;
  padding-bottom: 45px;

  legend {
    display: inline-block;
    margin-bottom: 0;
    overflow: hidden;
  }


  .repeatsection {
    margin-left: 20px;
    margin-right: 20px;
    background-color: fade(darken(@modal-content-bg, 7%), 25%);
    border: 1px solid @modal-content-border-color;
    border-radius: @border-radius-large;

    &:not(:first-child) {
      margin-top: 20px;
    }

    fieldset {
      margin-left: -15px
    }


    legend {
      //(@font-size-base * 1.5) for regular legends in forms.less
      font-size: (@font-size-base * 1.3);
      padding: 3px;
      margin-left: -7px;
      border: none;
      margin-bottom: 10px;
      overflow: hidden;
    }
  }

  .remove-button, .add-button {
    float: left;
    margin-left: 286px;
    margin-bottom: 15px;
  }

  .remove-button {
    margin-top: 10px;
  }

}



// https://stackoverflow.com/questions/62057910/chrome-83-change-colors-of-new-form-styling
input[type='checkbox']:checked {
  -webkit-filter: grayscale(100%);
}
input[type='radio']:checked {
  -webkit-filter: grayscale(100%);
}
