@grid-columns: 20;

@font-family-sans-serif: "Roboto", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-result-title: 13px;
@font-size-result-non-title: 12px;
@headings-font-weight: 400;
@line-height-large: 1.33;
@font-size-base: 13px;
@border-radius-large: 5px;
@border-radius-small: 2px;
@zindex-popover: 1010;
@zindex-tooltip: 1030;
@container-tablet: ((720px + @grid-gutter-width));
@container-desktop: ((940px + @grid-gutter-width));
@container-large-desktop: ((1140px + @grid-gutter-width));
@modal-inner-padding: 20px;
@badge-font-weight: normal;
@breadcrumb-separator: ">";

html,
body {
  height: 100%;
  background: @body-bg;
}

@import "partials/utilities.less";
@import "partials/type.less";
@import "partials/layout.less";
@import "partials/featurette.less";
@import "partials/forms.less";
@import "partials/tables.less";
@import "partials/icons.less";
@import "partials/navbar.less";
@import "partials/navs.less";
@import "partials/buttons.less";
@import "partials/pagination.less";
@import "partials/dropdowns.less";
@import "partials/bootstrap-switch.less";
@import "partials/block-ui.less";
@import "partials/panels.less";
@import "partials/charts.less";
@import "partials/tooltip.less";
@import "partials/modals.less";
@import "partials/chips.less";
@import "partials/popovers.less";
@import "partials/miscellaneous.less";
