@media (min-width: 1000px) {
  .modal-xl {
    width: 950px;
  }
}

@media (min-width: 1500px) {
  .modal-xl {
    width: 1400px;
  }
}

.cover-modal-dialog {
  .modal-dialog {
    width: min-content;
  }
}
