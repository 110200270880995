.nav-tabs > li, .nav-pills > li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  zoom: 1; /* hasLayout ie7 trigger */
}

.nav-tabs, .nav-pills {
  text-align: center;
}

.tab-content {
  padding-top: 30px;
}

.system-tab-content {
  margin: auto;
}

.config-tabs {
  margin-bottom: 20px;
}
