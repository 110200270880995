/* ------ featurette */
.featurette {
  display: table;
  width: 100%;
  height: 10%; /* set a pixel height and then remove the body,html height */
  vertical-align: middle;
  color: @body-bg;
}

.featurette-inner {
  display: table-cell;
  vertical-align: middle;
}

.featurette .search {
  padding: 3%;
  max-width: 980px; /*max width of search*/
  margin: 0 auto;
}
