.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: @block-overlay-bg;
}

.block-ui-message {
  background-color: @block-message-bg;
  color: @block-message-color;
}
