#banner {
  background: @banner-url no-repeat center;
  background-size: contain;
  height: 150px;
  margin: 0 15px 25px 15px;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}

.indexer-statuses-accordion {
  color: @text-color;
}

.stat-box {
  border-radius: @border-radius-small;
  border: 1px solid @table-border-color;

  .caption {
    margin-top: 10px;
    padding-left: 15px;

    .bootstrap-switch {
      margin-right: 20px;
    }
  }
}

.setting-wrapper {
  border-bottom: 1px solid @modal-content-border-color;

  .config-content {
    padding-right: 0;
  }

  .align-middle {
    align-items: center;
    min-height: 36px;
    display: flex;
  }
}

.config-box {

  .config-tooltip {
    padding-left: 15px;
    padding-right: 15px;
  }

  .config-help {
    padding-left: 15px;
    padding-right: 20px;
    width: 45%;
  }

}

.config-field-tooltip {
  max-width: 400px;
}

.config {
  .config-left-space {
    width: 10%;
  }
}
