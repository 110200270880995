.dropdown-menu {
  background-color: @input-bg;

  #freetext-filter-input {
    color: black;
  }
}

.dropdown-multiselect {
  &.form-control {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .dropdown-toggle {
    width: 100%;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  a:focus {
    outline: none;
  }
}

.scrollable-menu {
  height: auto;
  max-height: 600px;
  overflow-x: hidden;
}
