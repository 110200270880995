.navbar .nav,
.navbar .nav > li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  *zoom: 1; /* hasLayout ie7 trigger */
  vertical-align: top;
}

.navbar-inner {
  text-align: center;
}
