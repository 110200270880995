// Flexbox utilities (like Bootstrap 5)
.d-flex { display: flex; }
@media (min-width: @screen-sm-min) {
  .d-sm-flex {
    display: flex;
  }
}
@media (min-width: @screen-md-min) {
  .d-md-flex {
    display: flex;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.align-items-center {
  align-items: center !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-flex-end {
  justify-content: flex-end !important;
}
.justify-content-space-around {
  justify-content: space-around !important;
}

// Footers
#push,
#footer {
  height: 70px;
  z-index: 100;
}

#update-footer {
  position: fixed;
  left: 0;
  //bottom:35px;
  height: 70px;
  width: 100%;
}

#downloader-status-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.config-content {
  padding-right: 0;
}
