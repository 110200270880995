.bootstrap-switch {
  border: 1px solid @input-border;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  background-color: @brand-success;
  color: @switch-color;
}

.bootstrap-switch-advanced-toggle-wrapper {
  border: none;
  margin-top: 10px;
}
