.no-margin-top {
  margin-top: 0
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.expand-collapse-hidden {
  visibility: hidden;
}

.hidden-keep-space {
  visibility: hidden;
}

.caret-reversed {
  transform: rotate(180deg);
}

.visibility-hidden {
  visibility: hidden;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}


// Utilities (like Bootstrap 5)
.me-2 { margin-right: 0.5rem !important; }
.mb-0 { margin-bottom: 0 !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 1rem !important; }
.mb-4 { margin-bottom: 1.5rem !important; }
.mx-2 { margin-left: 0.5rem !important; margin-right: 0.5rem !important; }
.my-3 { margin-top: 1rem !important; margin-bottom: 1rem !important; }
.my-4 { margin-top: 1.5rem !important; margin-bottom: 1.5rem !important; }
.my-5 { margin-top: 3rem !important; margin-bottom: 3rem !important; }
.ms-4 { margin-left: 1.5rem !important; }
.mt-0 { margin-top: 0 !important; }
.pe-0 { padding-right: 0 !important; }
.pe-2 { padding-right: 0.5rem !important; }
.ps-0 { padding-left: 0 !important; }
.pt-0 { padding-top: 0 !important; }
.py-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
.py-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
@media (max-width: @screen-xs-max) {
  .mt-xs-2 { margin-top: 0.5rem !important; }
  .mx-xs-4 { margin-left: 1.5rem !important; margin-right: 1.5rem !important; }
  .pe-xs-0 { padding-right: 0 !important; }
}
