.rejected-tooltip {
  width: unset;
}

.rejected-tooltip-table {
  margin-top: 10px;
  background-color: transparent;
}

.nvtooltip {
  display: none !important;
}
