.panel {
  border-color: @navbar-default-border;
}

.panel-default > .panel-heading {
  background-color: @navbar-default-bg;

  h3 {
    color: @navbar-inverse-bg;
  }
}
